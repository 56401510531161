@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'), url('../fonts/fira/Firasanssemibold.woff2') format('woff2'), url('../fonts/fira/Firasanssemibold.woff') format('woff'), url('../fonts/fira/Firasanssemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Medium'), local('FiraSans-Medium'), url('../fonts/fira/Firasansmedium.woff2') format('woff2'), url('../fonts/fira/Firasansmedium.woff') format('woff'), url('../fonts/fira/Firasansmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'), url('../fonts/fira/Firasansmediumitalic.woff2') format('woff2'), url('../fonts/fira/Firasansmediumitalic.woff') format('woff'), url('../fonts/fira/Firasansmediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Italic'), local('FiraSans-Italic'), url('../fonts/fira/Firasansitalic.woff2') format('woff2'), url('../fonts/fira/Firasansitalic.woff') format('woff'), url('../fonts/fira/Firasansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'), url('../fonts/fira/Firasansextralight.woff2') format('woff2'), url('../fonts/fira/Firasansextralight.woff') format('woff'), url('../fonts/fira/Firasansextralight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'), url('../fonts/fira/Firasanslightitalic.woff2') format('woff2'), url('../fonts/fira/Firasanslightitalic.woff') format('woff'), url('../fonts/fira/Firasanslightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Light'), local('FiraSans-Light'), url('../fonts/fira/Firasanslight.woff2') format('woff2'), url('../fonts/fira/Firasanslight.woff') format('woff'), url('../fonts/fira/Firasanslight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Book'), local('FiraSans-Book'), url('../fonts/fira/Firasansbook.woff2') format('woff2'), url('../fonts/fira/Firasansbook.woff') format('woff'), url('../fonts/fira/Firasansbook.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Regular'), local('FiraSans-Regular'), url('../fonts/fira/Firasansregular.woff2') format('woff2'), url('../fonts/fira/Firasansregular.woff') format('woff'), url('../fonts/fira/Firasansregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Heavy'), local('FiraSans-Heavy'), url('../fonts/fira/Firasansheavy.woff2') format('woff2'), url('../fonts/fira/Firasansheavy.woff') format('woff'), url('../fonts/fira/Firasansheavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans SemiBold Italic'), local('FiraSans-SemiBoldItalic'), url('../fonts/fira/Firasanssemibolditalic.woff2') format('woff2'), url('../fonts/fira/Firasanssemibolditalic.woff') format('woff'), url('../fonts/fira/Firasanssemibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'), url('../fonts/fira/Firasansbolditalic.woff2') format('woff2'), url('../fonts/fira/Firasansbolditalic.woff') format('woff'), url('../fonts/fira/Firasansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans ExtraLight Italic'), local('FiraSans-ExtraLightItalic'), url('../fonts/fira/Firasansextralightitalic.woff2') format('woff2'), url('../fonts/fira/Firasansextralightitalic.woff') format('woff'), url('../fonts/fira/Firasansextralightitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Bold'), local('FiraSans-Bold'), url('../fonts/fira/Firasansbold.woff2') format('woff2'), url('../fonts/fira/Firasansbold.woff') format('woff'), url('../fonts/fira/Firasansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Heavy Italic'), local('FiraSans-HeavyItalic'), url('../fonts/fira/Firasansheavyitalic.woff2') format('woff2'), url('../fonts/fira/Firasansheavyitalic.woff') format('woff'), url('../fonts/fira/Firasansheavyitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans ExtraBold'), local('FiraSans-ExtraBold'), url('../fonts/fira/Firasansextrabold.woff2') format('woff2'), url('../fonts/fira/Firasansextrabold.woff') format('woff'), url('../fonts/fira/Firasansextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans Book Italic'), local('FiraSans-BookItalic'), url('../fonts/fira/Firasansbookitalic.woff2') format('woff2'), url('../fonts/fira/Firasansbookitalic.woff') format('woff'), url('../fonts/fira/Firasansbookitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans ExtraBold Italic'), local('FiraSans-ExtraBoldItalic'), url('../fonts/fira/Firasansextrabolditalic.woff2') format('woff2'), url('../fonts/fira/Firasansextrabolditalic.woff') format('woff'), url('../fonts/fira/Firasansextrabolditalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Thin'), local('BebasNeue-Thin'), url('../fonts/bebas/bebasneuethin.woff2') format('woff2'), url('../fonts/bebas/bebasneuethin.woff') format('woff'), url('../fonts/bebas/bebasneuethin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Light'), local('BebasNeue-Light'), url('../fonts/bebas/bebasneuelight.woff2') format('woff2'), url('../fonts/bebas/bebasneuelight.woff') format('woff'), url('../fonts/bebas/bebasneuelight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Book'), local('BebasNeueBook'), url('../fonts/bebas/bebasneuebook.woff2') format('woff2'), url('../fonts/bebas/bebasneuebook.woff') format('woff'), url('../fonts/bebas/bebasneuebook.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Regular'), local('BebasNeueRegular'), url('../fonts/bebas/bebasneueregular.woff2') format('woff2'), url('../fonts/bebas/bebasneueregular.woff') format('woff'), url('../fonts/bebas/bebasneueregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Bold'), local('BebasNeueBold'), url('../fonts/bebas/bebasneuebold.woff2') format('woff2'), url('../fonts/bebas/bebasneuebold.woff') format('woff'), url('../fonts/bebas/bebasneuebold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url('../fonts/roboto/Robotocondensedlight.woff2') format('woff2'), url('../fonts/roboto/Robotocondensedlight.woff') format('woff'), url('../fonts/roboto/Robotocondensedlight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url('../fonts/roboto/Robotocondensedlightitalic.woff2') format('woff2'), url('../fonts/roboto/Robotocondensedlightitalic.woff') format('woff'), url('../fonts/roboto/Robotocondensedlightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url('../fonts/roboto/Robotocondensed.woff2') format('woff2'), url('../fonts/roboto/Robotocondensed.woff') format('woff'), url('../fonts/roboto/Robotocondensed.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url('../fonts/roboto/Robotocondenseditalic.woff2') format('woff2'), url('../fonts/roboto/Robotocondenseditalic.woff') format('woff'), url('../fonts/roboto/Robotocondenseditalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url('../fonts/roboto/Robotocondensedbold.woff2') format('woff2'), url('../fonts/roboto/Robotocondensedbold.woff') format('woff'), url('../fonts/roboto/Robotocondensedbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url('../fonts/roboto/Robotocondensedbolditalic.woff2') format('woff2'), url('../fonts/roboto/Robotocondensedbolditalic.woff') format('woff'), url('../fonts/roboto/Robotocondensedbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}


body {
    background-color: #fff;
    font-family: 'Fira Sans', Arial;
    font-size: 16px;
    line-height: 24px;
    color: #273245;
}

.v-middle{
    vertical-align: middle;
}

p{
    margin-bottom: 20px;
}