.benefit-card {
    &__title {
        position: relative;
        margin-bottom: 20px;
        font-weight: bold;
        padding-left: 30px;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 17px;
            height: 15px;
            background: url(../img/triangle.svg) no-repeat center center / 100%;
        }
    }
}

.text-card {
    &__title {
        margin-bottom: 20px;
        font-weight: bold;
    }

    &__list {
        li {
            span {
                color: #606970;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

    &__price {
        margin-top: 20px;
    }

    &--main {
        
        p:first-child{
            margin-bottom: 10px;
        }
        
        .text-card__title {
            margin-bottom: 10px;
        }

        .text-card__price {
            margin-bottom: 10px;
            margin-top: 0;
        }
        .text-card__image {
            margin-top: 77px;
            max-width: 100%;
            @include max-screen(767px) {
                display: none;
            }
        }

        @include max-screen(767px) {
            margin-bottom: 40px;
        }

    }

    & > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        margin: 20px 0;
        padding: 0;
        list-style: none;
        li{
            &:before{
                content: '» ';
            }
        }
    }

    p {
        margin-bottom: 20px;
    }

    small {
        font-size: 14px;
        line-height: 130%;
        color: #606970;
    }
}
