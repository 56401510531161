.fadeInUp {
    position: relative;
    transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out, visibility 0.7s ease-in-out;
    opacity: 0;
    transform: translateY(120px); //transition-delay: 0.3s;
    z-index: 5;
    &.animate {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeIn {
    position: relative;
    transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out, visibility 0.7s ease-in-out;
    opacity: 0;
    z-index: 5;
    &.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
