.form {
    &__group {
        margin-bottom: 20px;
    }

    &__control {
        display: block;
        width: 100%;
        border: 1px solid #B2B2B2;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        font-size: 14px;
        &:focus {
            outline: 0;
            @include input-placeholder {
                opacity: 0;
            }
        }
        @include input-placeholder {
            color: #BDBDBD;
            opacity: 1;
            transition: all 0.3s linear;
        }

        &._warning {
            border-color: #fc9494;
        }
    }

    &__label {
        font-size: 16px;
        line-height: 150%;
        color: #4F4F4F;
        margin-bottom: 10px;
    }

}

button[type="submit"] {
    display: block;
    width: 100%;
    min-width: 0;
}

.custom-checkbox {
    position: relative;
    padding-left: 35px;

    &__input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        cursor: pointer;
    }

    &__input + &__label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        vertical-align: text-top;
        width: 24px;
        height: 24px;
        background: white;
        border: 1px solid #B2B2B2;
        transition: all 0.3s ease-in-out;
        z-index: 3;
    }

    &__input._warning + &__label:before {
        border-color: #fc9494;
    }

    &__input + &__label:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url("../img/checkbox-flag.svg") no-repeat center center;

        z-index: 4;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    &__input:checked + &__label:after {
        opacity: 1;
    }

    &__input:checked + &__label:before {
        background-color: #Fff;
    }

    &__label {
        font-size: 10px;
        line-height: 130%;
        color: #828282;
        display: block;
        font-weight: 300;
    }

    &--normal {
        .custom-checkbox__label {            
            font-size: 14px;
            line-height: 33px;
            height: 35px;
            width: auto;
            padding: 0 10px;
            margin: 0;
            color: $color-black;

            transition: all .3s ease-in-out;
        }
    }

}

.checkbox input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 20px;
}
.checkbox__text {
	position: relative;
    padding: 0 0 0 60px;
    cursor: pointer;
}
.checkbox__text:before {
	content: '';
	position: absolute;
	top: -4px;
	left: 0;
	width: 50px;
	height: 26px;
	border-radius: 13px;
	background: #CDD1DA;
	box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
	transition: .2s;
}
.checkbox__text:after {
	content: '';
	position: absolute;
	top: -2px;
	left: 2px;
	width: 22px;
	height: 22px;
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0 2px 5px rgba(0,0,0,.3);
	transition: .2s;
}
.checkbox input:checked + .checkbox__text:before {
	background: $color-primary;
}
.checkbox input:checked + .checkbox__text:after {
	left: 26px;
}
.checkbox input:focus + .checkbox__text:before {
	box-shadow: inset 0 2px 3px rgba(0,0,0,.2), 0 0 0 3px rgba(255, 157, 0, 0.7);
}



button {
    border: 0;
    padding: 0;
    background-color: transparent;
}

.custom-select {
    position: relative;
    font-size: 14px;

    &--active {
        .custom-select__arrow {
            transform: rotate(180deg);
            top: 50%;
        }
    }

    &__option {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;
        user-select: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: all 0.3s linear;

        &:not(&--value) {
            padding: 11px 10px 9px;
            &:hover {
                background-color: #E3E3E4;
            }
        }

        &--value {
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
            text-overflow: ellipsis;
            height: 40px;
            background-color: #EFEFEF;
            line-height: 42px;
            padding-left: 10px;
            padding-right: 50px;
        }
    }

    &__arrow {
        position: absolute;
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        transition: all 0.3s linear;
        z-index: 6;
        pointer-events: none;
        transform-origin: 50% 28%;
    }

    &__dropdown {
        position: absolute;
        background: #F0F0F0;
        box-shadow: 0px 0px 10px rgba(121, 118, 118, 0.15);
        width: 100%;
        top: calc(100% + 2px);
        overflow: hidden;
        z-index: 8;
    }

}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.c-select {
    color: #fff;
    width: 100%;
    border: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    height: 60px;
    background-color: #fff;
    border-radius: 50px;
    line-height: 60px;
    padding-left: 71px;
    padding-right: 51px;
}
