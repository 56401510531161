// Палитра

$color-black: #242222;
$color-gray-darker: #2d2d2d;
$color-gray-dark: #7c7575;
$color-gray: #999;
$color-gray-light: #e6e6e6;
$color-gray-lighter: #fafafa;
$color-white: #fff;
$black: $color-black;
$white: $color-white;

$color-primary-darker: #2d2d2d;
$color-primary-dark: #7c7575;
$color-primary: #FE8C2B;;
$color-primary-light: #e6e6e6;
$color-primary-lighter: #fafafa;

$color-secondary: blue;

$color-success: #6acb00;
$color-error: #ed1a3b;
$color-warning: #ffb11f;

// Шрифты

$font-family-base: '', Arial, sans-serif;
$font-family-secondary: '', Arial, sans-serif;
$font-size-base: 16px; // Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-weight-base: normal;
$line-height-base: $font-size-base;
$dt-font-weight: $font-weight-base;

// Боди

$body-color: $color-black;
$body-bg: $color-white;
$text-muted: $color-gray-light;

// Ссылки

$link-color: $color-secondary;
$link-decoration: underline;
$link-hover-color: $link-color;
$link-hover-decoration: none;

// Сетка

$grid-breakpoints: (
  xs: 0,
  sm: 575.98px,
  md: 767.98px,
  lg: 991.98px,
  xl: 1199.98px,
);

// Типография

$typography: (
  base: (
    base: (
      font-family: $font-family-base,
      font-size: $font-size-base,
      font-weight: 400,
      line-height: 30px,
    )
  ),
  sm: (
    base: (
      font-family: $font-family-base,
      font-size: 16px,
      font-weight: 300,
      line-height: 36px,
    ),
  ),
  xs: (
    base: (
      font-family: $font-family-base,
      font-size: 12px,
      font-weight: 400,
      line-height: 24px,
    ),
  ),
  h1: (
    base: (
      font-family: $font-family-secondary,
      font-size: 80px,
      font-weight: 500,
      line-height: 80px,
      text-transform: uppercase,
    ),
    lg: (
      font-size: 60px,
      line-height: 60px,
    ),
    sm: (
      font-size: 40px,
      line-height: 40px,
    ),
  ),
  h2: (
    base: (
      font-family: $font-family-secondary,
      font-size: 50px,
      line-height: 55px,
      font-weight: 300,
      text-transform: uppercase,
    ),
    md: (
      font-size: 40px,
      line-height: 44px,
    )
  ),
  h3: (
    base: (
      font-family: $font-family-secondary,
      font-size: 46px,
      line-height: 50px,
      font-weight: 500,
      text-transform: uppercase,
    ),
    lg: (
      font-size: 40px,
      line-height: 44px,
    ),
    md: (
      font-size: 30px,
      line-height: 33px,
    )
  ),
  h4: (
    base: (
      font-family: $font-family-secondary,
      font-size: 36px,
      line-height: 40px,
      font-weight: 500,
      letter-spacing: 1px,
      text-transform: uppercase,
    ),
    md: (
      font-size: 30px,
      line-height: 33px,
    )
  ),
  h5: (
    base: (
      font-family: $font-family-base,
      font-size: 14px,
      font-weight: 700,
      line-height: 38px,
      letter-spacing: 2px,
      text-transform: uppercase,
    ),
    sm: (
      font-size: 11px,
      line-height: 30px,
    )
  ),
  h6: (
    base: (
      font-family: $font-family-base,
      font-size: 24px,
      font-weight: 300,
      line-height: 42px,
    ),
  ),
  btn: (
    base: (
      font-family: $font-family-secondary,
      font-size: 18px,
      font-weight: 500,
      letter-spacing: 2px,
      text-transform: uppercase
    ),
    sm: (
      font-family: $font-family-base,
      font-size: 14px,
      font-weight: 700,
      letter-spacing: 1px,
      text-transform: uppercase
    ),
  ),
  nav: (
    base: (
      font-family: $font-family-secondary,
      font-size: 20px,
      font-weight: 500,
    ),
  ),
);