/* PLACEHOLDER MIXINS */

@mixin input-placeholder {
	&.placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}

	&::-webkit-input-placeholder {
		@content;
	}
}

/* PUSH AUTO MIXIN */

@mixin push-auto {
	margin: {
		left: auto;
		right: auto;
	}
}

/* PSEUDO MIXIN */

@mixin pseudo($display: block, $pos: absolute, $content: '') {
	content: $content;
	display: $display;
	position: $pos;
}

/* TRIANGLE MIXIN */

@mixin triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
	@include pseudo($pos: $position);
	width: 0;
	height: 0;

	@if $round {
		border-radius: 3px;
	}

	@if $direction==down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round($size / 2.5);
	}

	@else if $direction==up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round($size / 2.5);
	}

	@else if $direction==right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	}

	@else if $direction==left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}

/* HARDWARE MIXINS */

@mixin hw($backface: true, $perspective: 1000) {
	@if $backface {
		backface-visibility: hidden;
	}

	perspective: $perspective;
}

/* TRANSITION MIXINS */

@mixin transtion($property: all, $time: 0.3s, $eff: linear ) {
	-moz-transition: $property $time $eff;
	-o-transition: $property $time $eff;
	-webkit-transition: $property $time $eff;
	transition: $property $time $eff;
}

/* TRANSFORM MIXINS */

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
	@include transform(scale($scale));
}

@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transform-origin ($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}


/* MEDIA QUERIES MIXINS */

@mixin mq($args...) {
	$media-type: 'only screen';
	$media-type-key: 'media-type';
	$args: keywords($args);
	$expr: '';

	@if map-has-key($args, $media-type-key) {
		$media-type: map-get($args, $media-type-key);
		$args: map-remove($args, $media-type-key);
	}

	@each $key,
	$value in $args {
		@if $value {
			$expr: "#{$expr} and (#{$key}: #{$value})";
		}
	}

	@media #{$media-type} #{$expr} {
		@content;
	}
}

/* SCREEN */

@mixin screen($min, $max, $orientation: false) {
	@include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
		@content;
	}
}

@mixin max-screen($max) {
	
	@if map-get($grid-breakpoints, $max) {
		$max: map-get($grid-breakpoints, $max)
	}
	
	@include mq($max-width: $max) {
		@content;
	}
}

@mixin min-screen($min) {
	
	@if map-get($grid-breakpoints, $min) {
		$min: map-get($grid-breakpoints, $min)
	}
	
	@include mq($min-width: $min) {
		@content;
	}
}

@mixin screen-height($min, $max, $orientation: false) {
	
	@if map-get($grid-breakpoints, $max) {
		$max: map-get($grid-breakpoints, $max)
	}
	
	@if map-get($grid-breakpoints, $min) {
		$min: map-get($grid-breakpoints, $min)
	}
	
	@include mq($min-height: $min, $max-height: $max, $orientation: $orientation) {
		@content;
	}
}

@mixin max-screen-height($max) {
	
	@if map-get($grid-breakpoints, $max) {
		$max: map-get($grid-breakpoints, $max)
	}
	
	@include mq($max-height: $max) {
		@content;
	}
}

@mixin min-screen-height($min) {
	
	@if map-get($grid-breakpoints, $min) {
		$min: map-get($grid-breakpoints, $min)
	}
	
	@include mq($min-height: $min) {
		@content;
	}
}

/* TYPOGRAPHY GENERATE */

@mixin font-variant($styles, $color: false, $line-height: false, $align: false) {
	@if not $line-height {
		$line-height: map-get($styles, line-height);
	}

	$font-family: map-get($styles, font-family);
	$font-size: map-get($styles, font-size);
	$font-weight: map-get($styles, font-weight);
	$letter-spacing: map-get($styles, letter-spacing);
	$text-transform: map-get($styles, text-transform);

	@if $font-family {
		font-family: $font-family;
	}

	@if $font-size {
		font-size: $font-size;
	}

	@if $font-weight {
		font-weight: $font-weight;
	}

	@if $letter-spacing {
		letter-spacing: $letter-spacing;
	}

	@if $text-transform {
		text-transform: $text-transform;
	}

	@if $line-height {
		line-height: $line-height;
	}

	@if $color {
		color: $color;
	}

	@if $align {
		text-align: $align;
	}
}

@mixin font($variant: base, $color: false, $line-height: false, $align: false, $responsive: true) {
	$variants: map-get($typography, $variant);

	@each $breakpoint,
	$styles in $variants {
		@if $breakpoint==base {
			@include font-variant($styles, $color, $line-height, $align);
		}

		@else {
			@if $responsive {
				@include max-screen(map-get($grid-breakpoints, $breakpoint)) {
					@include font-variant($styles, $color, $line-height, $align);
				}
			}
		}
	}
}