.wrapper {
    overflow: hidden;
}

.section {
    &--gray {
        background-color: #F0F1F3;
        padding-top: 60px;
        padding-bottom: 60px;

        @include max-screen(767px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

    }
    &--white {
        padding-top: 160px;
        padding-bottom: 160px;
        background-color: #fff;
        @include max-screen(991px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        @include max-screen(767px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    &__header {}

    &__title {
        font-weight: normal;
        font-family: 'Roboto Condensed', Arial;
        font-size: 36px;
        line-height: 130%;
        margin-bottom: 40px;
        @include max-screen(767px) {
            margin-bottom: 20px;
        }
    }

    &__description {
        margin-top: 40px;
    }

}

.adv {
    display: flex;
    &__item {
        margin-right: 40px;
        display: flex;
        align-items: center;
        &:last-child {
            margin-right: 0;
        }
    }
    &__number {
        font-family: 'Roboto Condensed', Arial;
        color: #FE8C2B;
        font-size: 36px;
        line-height: 130%;
        margin-right: 10px;
    }

    &__title {
        font-size: 14px;
        line-height: 130%;
        color: #606970;
    }
}

.main-banner {
    position: relative;
    background: url(../img/main--bg.jpg) no-repeat center right / auto;
    height: 870px;

    @include max-screen(991px) {
        height: 650px;
    }

    @include max-screen(767px) {
        height: auto;
    }

    &:after {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #F0F1F3;
        z-index: 10;
        @include max-screen(991px) {
            width: 100%;
            opacity: 0.9;
        }
        @include max-screen(767px) {
            opacity: 1;
        }
    }

    &__inner {
        position: relative;
        z-index: 11;
        height: 100%;
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 50%;
        padding: 264px 15px 24px 0;
        @include max-screen(991px) {
            width: 100%;
            padding: 156px 15px 24px 0;
        }

        @include max-screen(767px) {
            padding: 102px 0 26px;
        }

    }

    &__content {
        flex: 0 0 auto;
        max-width: 100%;
    }

    &__subtitle {
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 10px;
        color: #606970;
    }

    &__title {
        font-family: 'Bebas Neue';
        font-size: 64px;
        line-height: 100%;
        margin-bottom: 20px;
        @include max-screen(767px) {
            font-size: 55px;
            line-height: 100%;
        }

    }

    &__description {
        color: #606970;
        margin-bottom: 40px;
    }
    &__adv {
        flex: 0 0 auto;
        max-width: 100%;
        margin-top: 40px;
        @include max-screen(767px) {
            display: none;
        }
    }

    &__btn {
        @include max-screen(575px) {
            min-width: 0;
            width: 100%;
        }
    }

}

.benefit {
    padding-bottom: 80px;

    @include max-screen(991px) {
        padding-bottom: 40px;
    }

    @include max-screen(767px) {
        padding-bottom: 20px;
    }

    &__header {
        @include max-screen(767px) {
            margin-bottom: 40px;
        }
    }

    &__part {
        margin-bottom: 120px;
        @include max-screen(767px) {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.benefit-list {
    &__item {
        margin-bottom: 40px;
    }
}

.price {
    font-family: 'Roboto Condensed', Arial;
    color: #FE8C2B;
    font-size: 18px;
    line-height: 150%;
    &--big {
        font-size: 24px;
        line-height: 130%;
    }
}


.example {

    @include max-screen(991px) {
        padding-bottom: 20px;
    }

    &__header {
        margin-bottom: 20px;
    }
    &-list {
        &__item {
            margin-bottom: 40px;
            &.m-0 {
                @include max-screen(991px) {
                    margin-bottom: 40px !important;
                }
            }
        }
    }
}

.construct {
    padding-top: 80px;
    padding-bottom: 160px;

    @include max-screen(991px) {
        padding-bottom: 120px;
        padding-top: 60px;
    }

    @include max-screen(767px) {
        padding-bottom: 80px;
        padding-top: 40px;
    }

    &__image {
        @include max-screen(991px) {
            display: none;
        }
        img {
            max-width: 100%;
        }

        &--mobile {
            display: none;
            @include max-screen(991px) {
                display: block;
                margin-bottom: 40px;
            }
        }
    }

    &__form {
        margin-top: 30px;
    }

    &__sum {
        margin-bottom: 36px;
        margin-top: 20px;
        span {
            margin-left: 10px;
        }
    }
}

.build {
    padding-bottom: 80px;

    @include max-screen(991px) {
        padding-bottom: 60px;
    }

    @include max-screen(767px) {
        padding-bottom: 40px;
    }

    &__part {
        margin-bottom: 40px;

        @include max-screen(767px) {
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
        img {
            max-width: 100%;
        }
    }

    &__item {
        @include max-screen(991px) {
            margin-bottom: 40px;
        }
    }

    &__image {
        @include max-screen(767px) {
            margin-top: 20px;
        }
    }
}

.econom {
    padding-top: 80px;

    @include max-screen(991px) {
        padding-top: 60px;
    }

    @include max-screen(767px) {
        padding-top: 40px;
    }
}

.works {
    padding-bottom: 92px;

    @include max-screen(991px) {
        padding-bottom: 52px;
    }

    &__text {
        color: #606970;
    }

    &__form {
        @include max-screen(991px) {
            margin-bottom: 40px;
        }
    }

    &__contacts {
        @include max-screen(767px) {
            margin-bottom: 16px;
        }
    }

}

.contact-block {
    &__item {
        font-family: 'Roboto Condensed', Arial;
        font-size: 24px;
        line-height: 130%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }

        &--small {
            font-size: 16px;
            line-height: 150%;
        }

        a {
            color: #606970;
            &:hover {
                color: #FE8C2B;
            }
        }
    }
}

.popup {
    width: 500px;
    padding: 40px 30px 40px;

    &__header {
        text-align: center;
        font-size: 24px;
        line-height: 100%;
        margin-bottom: 30px;
    }

    &--success {
        .popup__header {
            margin-bottom: 0;
        }
    }

    &--text {
        width: 800px;
    }

}
