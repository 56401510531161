.header {
    position: fixed;
    background-color: #fff;
    height: 80px;
    padding: 8px 0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    transition: all 0.3s linear;

    &--fixed {
        box-shadow: 0 15px 25px -15px rgba(162, 162, 162, .8);
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__right {
        display: flex;
        @include max-screen(767px) {
            display: none;
        }
    }

    &__mobile {
        display: none;
        @include max-screen(767px) {
            display: flex;
        }
    }

    &__m-phone {
        margin-right: 30px;
    }

    &-burger {
        border: 0;
        padding: 0;
        background-color: transparent;
        align-self: center;

        @include max-screen(1199px) {
            display: block;
        }

        span {
            display: block;
            height: 2px;
            width: 26px;
            background-color: #606970;
            border-radius: 6px;
            margin-bottom: 8px;
            transition: all 0.3s ease-in-out;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--open {
            span {
                &:first-child {
                    transform: translateY(10px) rotate(45deg);
                }


                &:nth-child(2) {
                    transform: rotateY(90deg);
                }

                &:last-child {
                    transform: translateY(-10px) rotate(-45deg);
                }
            }
        }

    }

    &-nav {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        &__item {
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }

        &__link {
            color: #333333;
        }
    }
    &__phone {
        margin-left: 60px;
        span {
            color: #FE8C2B;
        }
        a {
            color: #333333;
            &:hover {
                color: #FE8C2B;
            }
        }
    }
}

.mobile-pane {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-120%);
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    height: 100%;
    width: 300px;
    z-index: 99;
    box-shadow: 4px 0 16px rgba(0, 0, 0, 0.14);
    transition: all .3s linear;
    z-index: 15;

    &--open {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
}

.mobile-nav {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        border-bottom: 1px solid #F0F1F3;
        &:last-child {
            border-bottom: 0;
        }
    }

    &__link {
        display: block;
        padding: 20px 15px;
        color: #333333;
        &:hover {
            color: #FE8C2B;
        }
    }
}
