button{
    &:focus{
        outline: 0;
    }
}

a{
    transition: all 0.3s linear;
    &:hover{
        color: #FE8C2B;
        text-decoration: none;
    }
}

.btn{
    border: 0;
    text-align: center;
    display: inline-block;
    height: 60px;
    min-width: 290px;
    line-height: 60px;
    padding: 0 20px;
    transition: all 0.3s linear;
    &--primary{
        color: #F2F2F2;
        background-color: #606970;
        &:hover{
            background-color: #FE8C2B;
            color: #fff;
        }
    }
    
    &--orange{
        color: #F2F2F2;
        background-color: #FE8C2B;
        &:hover{
            background-color: #606970;
            color: #fff;
        }
    }
}

.scroll-top{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    background-color: #606970;
    z-index: 14;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
    
    &--show{
        visibility: visible;
        opacity: 1;
    }
    
}