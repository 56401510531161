.footer {
    padding-top: 36px;
    padding-bottom: 27px;
    background-color: #333333;
    &-item {

        @include max-screen(991px) {
            margin-bottom: 20px;
        }

        &__title {
            font-size: 14px;
            line-height: 150%;
            color: #F0F1F3;
            margin-bottom: 15px;
        }
        &__content {
            font-size: 14px;
            line-height: 130%;
        }

        &__links {
            a {
                color: #FE8C2B;
                &:hover {
						  text-decoration: underline;
						  color: #fff;
                }
            }
            small {
                color: #606970;
                font-size: 12px;
                line-height: 150%;
                font-weight: 300;
            }
        }

        &--logo {
            display: none;
            align-items: flex-end;
            @include max-screen(991px) {
                display: flex;
            }
            @include max-screen(767px) {
                order: 1;
            }
        }

        &:nth-child(1) {
            @include max-screen(767px) {
                order: 2;
            }
        }
        &:nth-child(2) {
            @include max-screen(767px) {
                order: 3;
            }
        }
        &:nth-child(4) {
            @include max-screen(767px) {
                order: 4;
            }
        }

    }

    &__copyright {
        color: #606970;
        font-size: 12px;
        line-height: 150%;
        font-weight: 300;
        margin-top: 55px;
        padding-top: 20px;
        border-top: 1px solid #606970;
        @include max-screen(991px) {
            margin-top: 20px;
        }
    }

    &__logo {
        display: inline-block;
        vertical-align: middle;
    }

}
